import Grid from '@mui/material/Grid2';
import { React, useState, useEffect } from "react";
import JMLogo from "../../../Images/JayMamta-Logos/JM-Logo.png";
import SecondaryBtn from '../SecondaryButton';
import { motion } from "framer-motion";


export default function HeroSection() {
    const colors = ["#ff5733", "#33ff57", "#3357ff", "#ff33a8", "#ffaa33", "#33ff57", "#3357ff", "#ffaa33"];
    const words = ["Technology", "Farming", "Health Care", "Garment", "Agriculture", "Multiplex", "Education", "Real Estate"];
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [words.length]);
    return (
        <>
            <Grid className="hero-bg">
                <Grid>
                    <Grid display="flex" justifyContent="center">
                        <motion.img
                            src={JMLogo}
                            alt="Floating Image"
                            animate={{
                                scale: [1, 1.05, 1]
                            }}
                            transition={{
                                duration: 2, // Smooth transition
                                ease: "easeInOut",
                                repeat: Infinity, // Infinite floating animation
                                repeatType: "loop", // Move up and down smoothly
                            }}
                        />
                    </Grid>
                    <h2 className='hero-bg-h1'>Transforming Dreams into Reality</h2>
                    <h2 className='hero-bg-h2'>Leading Growth in Every Industry</h2>
                    <h2 className='hero-bg-h2'>
                        <motion.span
                            key={index} // Ensures animation triggers on change
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.5, ease: "easeInOut" }}
                            // style={{ color: "#ffaa33" }}
                            style={{ color: colors[index] }}
                        >
                            {words[index]}
                        </motion.span>
                    </h2>
                    <Grid display="flex" justifyContent="center" marginTop={5}>
                        <SecondaryBtn name="READ MORE" />
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}